import styled from "styled-components"
import { ImageWithLinkOrNot } from "../ImageWithLinkOrNot"

export const Wrapper = styled.div`
  padding: 1em;
  height: 8.5em;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-items: center;
  font-family: ${({ theme }) => theme.typography.info.fontFamily};
  font-size: ${({ theme }) => theme.typography.info.fontSize};
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.main};
  grid-gap: 1em;
  justify-content: space-evenly;
  &.mobile {
    display: flex;
    height: auto;
    margin-top: 0;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }
`
export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 0px;
  gap: 3em;
  max-width: 30em;
  padding-left: 7em;
  &.mobile {
    padding: 0px;
    width: 100%;
    justify-content: center;
  }
`

export const Img = styled(ImageWithLinkOrNot)`
  max-height: 63px;
  width: 100%;
  flex-basis: 65px;
`

export const Text = styled.div`
  text-align: left;
  font: normal normal 600 30px/26px Cormorant Garamond;
  color: white;
  &.mobile {
    width: 60%;
  }
`

export const Text1 = styled(Text)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`
