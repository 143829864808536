import styled from "styled-components"
import { Icon as IconBase } from "./../../Icon"

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

export const Wrapper = styled.div`
  z-index: 250;
  width: 100%;
  max-width: 40em;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.background.default};
  box-shadow: ${({ theme }) => theme.shadows[4]};
  border-radius: 8px;
  padding: 1.5em;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.typography.color};

  h2 {
    font-size: ${({ theme }) => theme.typography.h2.fontSize};
    margin-bottom: 1em;
    font: normal normal 600 26px Cormorant Garamond;
  }

  p {
  width: 100%;
    margin-bottom: 0.75em;
    line-height: 1.5;
    text-align: left;
    font: normal normal 400 12px Poppins;
  }

  &.mobile {
    width: 90%;
    max-width: 30em;
    overflow-y: auto;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const Icon = styled(IconBase)`
  padding-right: 3%;
  cursor: pointer;
`
