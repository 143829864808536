import React from "react"
import {
  StyledP,
  Domaine,
  EncartDomaine,
  ItemDomaine,
  SousDomaineWrapper,
  Div,
  Photo,
  SousDomaineItemsView,
  SousDomaineMarqueItemsView,
  LinkAll,
  ItemLink,
} from "./DomaineItem.style"


interface Props {
  className?: string
  data: Object
  isActive: boolean
  displayContent: boolean
}

export function DomaineItemView({
  data,
  isActive,
  displayContent,
}: Props) {

  const getContent = data => {
    return (
      <SousDomaineWrapper>
        {data.children && (
          <>
            {(data.name != "Marques") && (
              <>
                <SousDomaineItemsView data={data.children} />
                {data.category?.backgroundImage?.url && (
                  <EncartDomaine>
                    <Photo src={data.category?.backgroundImage?.url} />
                  </EncartDomaine>
                )}
                {data.category?.slug && (
                  <LinkAll link={"/" + data.category?.slug} children={"Tout voir"}></LinkAll>
                )}
              </>
            )}
            {(data.name == "Marques") && <SousDomaineMarqueItemsView data={data.children} />}
          </>
        )}
      </SousDomaineWrapper>
    )
  }

  const hasNoChild = data.children?.length <= 0
  const slug = "/" + getSlug(data)
  return (
    <Div>
      <Domaine isActive={isActive}>
        {(hasNoChild || displayContent) && (
          <ItemLink
            link={"/" + getSlug(data)}
            hasNoChild={hasNoChild}
            isActive={isActive}
          >
            <StyledP>{data.name}</StyledP>
          </ItemLink>
        )}
        {!(hasNoChild || displayContent) && (
          <ItemDomaine
            hasNoChild={hasNoChild}
            isActive={isActive}
          >
            <StyledP>{data.name}</StyledP>
          </ItemDomaine>
        )}
      </Domaine>
      {displayContent && !hasNoChild && getContent(data)}
    </Div>
  )
}

export const getSlug = item => {
  return item.category?.slug || item.collection?.slug || item.page?.slug
}
