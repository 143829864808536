import React, { useState, useEffect } from "react"
import { Content, Wrapper, Overlay, Icon, Row } from "./PopUpInfos.style"
import { useDetectDevice } from "../../../../lib/useDetectDevice"

interface Props {
  data: Record<string, any>
  className?: string
}

// si popup fermé on ne l'affiche plus de la journée
const hasPopupBeenClosedToday = () => {
  const lastClosedDate = localStorage.getItem("popupClosedDate")
  const today = new Date().toISOString().split("T")[0]
  return lastClosedDate === today
}

//si pas fermé aujourd'hui on l'affiche
const setPopupClosedToday = () => {
  const today = new Date().toISOString().split("T")[0]
  localStorage.setItem("popupClosedDate", today)
}

export function PopUpInfosView({}: Props) {
  const [isVisible, setIsVisible] = useState(!hasPopupBeenClosedToday())
  const isMobile = useDetectDevice()

  useEffect(() => {
    if (!isVisible) {
      setPopupClosedToday()
    }
  }, [isVisible])

  const closePopup = () => setIsVisible(false)

  if (!isVisible) return null

  return (
    <Overlay onClick={closePopup}>
      <Wrapper onClick={e => e.stopPropagation()}>
        <Content>
          <Row>
            <Icon icon="cross" onClick={closePopup} />
          </Row>
          <h2>Mise à jour importante concernant vos informations personnelles</h2>
          <p>
            Dans le cadre de l’évolution des réglementations en matière de
            paiement en ligne, notamment l’application de la norme 3D Secure V2,
            certaines informations deviennent désormais obligatoires pour
            assurer la sécurité de vos transactions.
          </p>
          <p>
            Par mesure de conformité, les adresses clients dépourvues de numéro de téléphone ont été
            supprimées du compte afin que vous puissiez mettre à jour ces informations.
          </p>
          <p>
            Pour garantir la continuité de vos commandes sans interruption, nous
            vous invitons à vérifier et, si nécessaire, à recréer vos adresses
            directement depuis votre espace client.
          </p>
          <a href="/app/profile" onClick={closePopup} >Accéder à mon espace client</a>
          <p>
          Nous vous remercions de votre compréhension et restons à votre disposition pour toute question ou assistance.
          </p>
          <p>Bien cordialement,</p>
          <h4>L’équipe Grandes Marques</h4>
        </Content>
      </Wrapper>
    </Overlay>
  )
}
