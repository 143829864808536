import styled from "styled-components"
import { ImageWithLinkOrNot } from "../../../common/ImageWithLinkOrNot"
import { CommonSubtitle, CommonParagraph } from "../../../common/Texts"
import { Alerte as AlerteBase } from "../../TemplateProduct/ProductSelection/ProductSelection.style"


export const Wrapper = styled.div`
display: flex;
flex-direction: column;
`

export const WrapperInfos = styled.div`
  padding: 20px 0;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`
export const WrapperVariants = styled.div`
  display: flex;
  justify-content: space-between;
  gap:10px;
  align-items: center;
  box-sizing: border-box;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
`

export const ColumnProd = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
`

export const ItemImage = styled(ImageWithLinkOrNot)`
  display: flex;
  width: 100px;
  height:100px;
  object-fit:contain;
`

export const ProductName = styled(CommonParagraph)`
  text-transform: uppercase;
  margin-bottom: -1px;
  font-size: 80%;
`
export const VariantName = styled(CommonParagraph)`
  font-size: 80%;
`

export const OriginalPrice = styled(CommonSubtitle)`
  font-size: 1.5em;
  display: flex;
  justify-content: flex-end;
  margin: 0;
`

export const PriceDetails = styled.div`
  display: flex;
  flex-direction: column;
`
export const BinPosition = styled.div`
  padding-right: 1%;
  margin-bottom: 0.5em;
  padding-left: 1%;
`

export const OldPrice = styled(OriginalPrice)`
  font-size: 1.2em;
  text-decoration-line: line-through;
`

export const DiscountPrice = styled(OriginalPrice)``

export const TotalPrice = styled.div`
  font-size: 10px;
  width: 100%;
  padding-top: 0.5em;
`

export const Alerte = styled(AlerteBase)`
  font: italic normal 300 14px/18px Poppins;
  background-color: white;
  color: ${({ theme }) => theme.palette.secondary.main};
`