import styled, { keyframes } from "styled-components"
import { Cart as CartBase } from "../../specific/Cart"
import { CartItemS } from "../../specific/Cart/Cart.style"
import { BinPosition } from "../../specific/Cart/CartItem/CartItem.style"
import { CommonWrapper } from "../CommonWrapper"
import { Icon } from "../Icon"
import { ImageWithLinkOrNot } from "../ImageWithLinkOrNot"
import { Popin as PopinBase } from "./../Popin"
import { NavBar as NavBarBase } from "./NavBar"
import { SearchBar as SearchBarBase } from "./SearchBar"

export const CommonWrapperHeader = styled(CommonWrapper)`
  padding: 0;
  position: fixed;
  top: 0px;
  background-color: white;
  z-index: 100;
  width: 100%;
`

export const Wrapper = styled.div`
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  z-index: 100;
`

export const WrapperShrinked = styled(Wrapper)`
  padding-top: 0;
  height: 160px;
`

export const WrapperMobile = styled.div`
  height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 5px;
  gap: 10px;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  & > button,
  & > img {
    flex-grow: 0;
  }
`

export const StyledIcon = styled(Icon)`
  ${({ open }) => (open ? "width:100px !important" : "")};
`

export const Raw = styled.div`
  padding: 10px 0;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  justify-content: space-around;
  justify-items: center;
  align-items: center;
  background-color: #f8f8f8;
  justify-items: stretch;
  gap: 2em;
`
export const RawSmall = styled(Raw)`
  grid-template-columns: 1fr 2.5fr 2.5fr;
`
export const Tel = styled.a`
  /* UI Properties */
  text-decoration: none;
  text-align: center;
  font: normal normal bold 16px/22px Poppins;
  color: #b99669;
`
export const Flex = styled.div`
  display: flex;
  padding-right: 1em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Col = styled.div``

export const Left = styled(Col)`
  justify-self: flex-start;
  padding-left: 1em;
  max-width: 300px;
`

export const Middle = styled(Col)`
  margin: 0 auto;
  justify-self: center;
  width: 100%;
  max-width: 700px;
`

export const MiddleShrinked = styled(Middle)``

export const Right = styled(Col)`
  justify-self: center;
  max-width: 400px;
`

export const SearchBar = styled(SearchBarBase)`
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 60px;
  opacity: 1;
`
export const SearchBarMobile = styled(SearchBarBase)`
  ${props => (props.open ? SearchBarMobileOpen() : SearchBarMobileClose())};
`
const SearchBarMobileClose = () => `
width: 25px;
height: 25px;
`
const SearchBarMobileOpen = () => `
width: 100%;
display:inline-flex;
`
export const Logo = styled(ImageWithLinkOrNot)`
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  cursor: pointer;
`
export const IconLink = styled(ImageWithLinkOrNot)`
  width: 35px;
  height: 35px;
`

export const LogoMobile = styled(Logo)`
  width: 100%;
  height: 2.5em;
`

export const Icons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-content: center;
  cursor: pointer;
`

export const QuestionRespons = styled.div`
  display: flex;
  gap: 0.5em;
  justify-content: flex-end;
  flex-direction: row;
  align-content: center;
  cursor: pointer;
`

export const Glissement = styled.div`
  cursor: pointer;
`

export const RightMobile = styled.div`
  display: flex;
  margin-right: 2em;
  &.mobile {
    margin: 0;
    padding: 0;
  }
`

export const IconRight = styled(Icon)`
  margin-left: auto !important;
`

export const NavBar = styled(NavBarBase)`
  box-sizing: border-box;
`

export const PastilleCompteur = styled.div`
  z-index: 10;
  width: 23px;
  height: 23px;
  position: absolute;
  margin-top: 1.75em;
  margin-right: 3.75em;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  color: black;
  font-weight: ${({ theme }) => theme.typography.h2.fontWeight};
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: ${({ theme }) => theme.typography.info.fontSize};
  background: white;
`

export const Cart = styled(CartBase)`
  justify-content: flex-start;
  ${CartItemS} {
    padding: 5px;
  }
  ${BinPosition} {
    //display: none;
  }
`

export const PastilleCompteurMobile = styled.div`
  z-index: 10;
  width: 20px;
  height: 20px;
  margin-top: 20px;
  margin-left: -30px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  border-radius: 10px;
  color: white;
  font-weight: ${({ theme }) => theme.typography.h2.fontWeight};
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: 0.6rem;
  background: ${({ theme }) => theme.palette.button.homepage};
`

const openingMenu = keyframes`
  from {left: -100%;}
  to {left: 0;}
`
const closingMenu = keyframes`
  from {left: 0;}
  to {left: -100%;}
`
export const MenuPopin = styled(PopinBase)`
  overflow-y: auto;
  left: -100%;
  &.open {
    animation: ${openingMenu} 0.5s cubic-bezier(1, 0, 0, 1);
    left: 0;
  }
  &.close {
    animation: ${closingMenu} 0.5s cubic-bezier(1, 0, 0, 1);
    left: -100%;
  }
`

const openingCart = keyframes`
  from {right: -100%;  background-color: rgba(44, 46, 44, 0);}
  to {right: 0;    background-color: rgba(44, 46, 44, 0.8);}
`
const closingCart = keyframes`
  from {right: 0;    background-color: rgba(44, 46, 44, 0.8);}
  to {right: -100%;  background-color: rgba(44, 46, 44, 0);}
`
export const Popin = styled(PopinBase)`
  justify-content: flex-end !important;
  right: -100%;
  background-color: rgba(44, 46, 44, 0);
  &.open {
    animation: ${openingCart} 0.5s cubic-bezier(1, 0, 0, 1);
    right: 0;
    background-color: rgba(44, 46, 44, 0.8);
  }
  &.close {
    animation: ${closingCart} 0.5s cubic-bezier(1, 0, 0, 1);
    right: -100%;
    background-color: rgba(44, 46, 44, 0);
  }
`
export const Title = styled.div`
  text-align: left;
  font: normal normal normal 16px/22px Poppins;
  color: #2b3944;
  white-space: nowrap;
  cursor: default;
`
export const Bold = styled(Title)`
  font-weight: bold;
`

export const SubTitle = styled(Title)`
  text-align: center;
  font: normal normal bold 18px/22px Cormorant Garamond;
  letter-spacing: 6.3px;
  color: #293b4e;
`
