import React from "react"
import { FlashMessageQuery } from "./FlashMessage.queries"
import { Wrapper } from "./FlashMessage.style"

interface Props {
  data: Record<string, any>
  className?: string
}

export function FlashMessageView({ className }: Props) {
  const data = FlashMessageQuery()
  const gmMessageBox = data.strapiGmMessageBox

  return (
    <>
      {gmMessageBox.Text != " " && (
        <Wrapper
          className={className}
          backgroundColor={gmMessageBox.BackgroundColor}
          fontColor={gmMessageBox.FontColor}
        >
          {gmMessageBox.Text}
        </Wrapper>
      )}
    </>
  )
}
