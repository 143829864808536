import React, { useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { decrementPrice } from "../../../helper/decrementPrice"
import { fixPrice } from "../../../helper/fixedPrice"
import { useDetectDevice } from "../../../lib/useDetectDevice"
import {
  CartItemS,
  Price,
  PriceBold,
  Pricing,
  Row,
  RowDown,
  Text,
  TextBold,
  Wrapper,
  Div,
  CommonWrapperCart,
  LinkCheckout,
} from "./Cart.style"
import { Spinner } from "../../common/Spinner"
import { useCartWithTotal } from "../../../hooks/useCartWithTotal"
import { gtagger } from "../../../helper/gtag"
import { useAuth } from "@saleor/sdk"
import { Link } from "gatsby"

interface Props {
  className?: string
  handleClose: Function
  redirectUrl?: string
  onLoadMore: () => void
}

export function CartView({ redirectUrl = "/app/checkout" }: Props) {
  const { loaded, items, totalPricing } = useCartWithTotal()
  const { user } = useAuth()
  const isMobile = useDetectDevice()

  if (!loaded) return <Spinner />

  const { totalCurrent, totalOriginal } = totalPricing

  const classNames = isMobile ? "mobile" : ""

  if (items && totalOriginal?.gross?.amount) {
    return (
      <PrintLines
        items={items}
        totalOriginal={totalOriginal}
        totalCurrent={totalCurrent}
      >
        <Pricing>
          <Row>
            <TextBold> Total</TextBold>
            <Price>
              {fixPrice(totalOriginal.gross.amount)}
              {totalOriginal.currency}
            </Price>
          </Row>
          <Row>
            <Text> Remises</Text>
            <Price>
              {fixPrice(
                decrementPrice(
                  totalOriginal.gross.amount,
                  totalCurrent.gross.amount
                )
              )}
              {totalOriginal.currency}
            </Price>
          </Row>
          <RowDown>
            <TextBold> Total remisé</TextBold>
            <PriceBold>
              {fixPrice(totalCurrent.gross.amount)}
              {totalCurrent.currency}
            </PriceBold>
          </RowDown>
          {items.length > 0 && user.addresses.length > 0 && (
            <LinkCheckout
              to={redirectUrl}
              onClick={() => {
                gtagger("buy", { price: totalCurrent?.gross?.amount })
              }}
            >
              COMMANDER
            </LinkCheckout>
          )}
          {items.length > 0 && user.addresses.length == 0 && (
            <Link to="/app/profile">
              Veuillez cliquer ici pour ajouter une adresse à votre compte et continuer votre commande.
            </Link>
          )}
        </Pricing>
      </PrintLines>
    )
  }
  return (
    <CommonWrapperCart>
      <Wrapper className={classNames}>Votre panier est vide</Wrapper>
    </CommonWrapperCart>
  )
}

const PrintLines = ({ items, children }) => {
  const [getList, setList] = useState([])

  useEffect(() => {
    setList(items)
    // // Saleor SDK use localStorage to save cart before sync with saleor backend, I want to retrieve items when user sign in if he doesn't have a cart on backend
    // if (localStorage.getItem('data_checkout')) {
    //   let data_checkout = localStorage.getItem('data_checkout')
    //   if(data_checkout){
    //     localStorage.setItem('data_checkout_tmp', data_checkout)
    //   }
    // }
  }, [items])

  return (
    <CommonWrapperCart>
      <Wrapper>
        <Div>
          {getList.map(item => {
            return <CartItemS key={uuidv4()} {...item} />
          })}
        </Div>
        {children}
      </Wrapper>
    </CommonWrapperCart>
  )
}
