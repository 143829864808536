import React, { useState, useEffect, useRef } from "react"
import { v4 as uuidv4 } from "uuid"
import { Props } from "../NavBar.view"
import { DomaineItem } from "./DomaineItem"
import { Wrapper, Div } from "./NavBarDesktop.style"

export function NavBarDesktopView({ className, data }: Props) {
  const [activeTab, setActiveTab] = useState()
  const [displayTabItems, setDisplayTabItems] = useState()
  // const myTimer = useRef(0)

  const menuRef = useRef()

  const displayMenu = (index) => {
    if (activeTab === index) {
      hideMenu()
    } else {
      setActiveTab(index)
      setDisplayTabItems(index)
    }
  }

  const hideMenu = () => {
    setActiveTab(null)
    setDisplayTabItems(null)
  }

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        hideMenu()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])


  return (
    <Wrapper ref={menuRef} >
      {data &&
        data.map((item: any, index: number) => {
          return (
            <Div
              key={uuidv4()}
              onClick={() => {
                displayMenu(index)
              }}
            >
              <DomaineItem
                data={item}
                isActive={activeTab == index}
                displayContent={displayTabItems == index}
              ></DomaineItem>
            </Div>
          )
        })}
    </Wrapper>
  )
}
