import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setHeaderStatus } from "../../../entities/headerStatus/headerStatus.actions"
import { useDetectDevice } from "../../../lib/useDetectDevice"
import { enumFrom } from "../ImageWithLinkOrNot"
import { FlashMessage } from "./../Layout/FlashMessage"
import {
  Cart,
  CommonWrapperHeader,
  Flex,
  Icons,
  QuestionRespons,
  SubTitle,
  Left,
  Logo,
  LogoMobile,
  MenuPopin,
  Middle,
  NavBar,
  PastilleCompteur,
  Popin,
  Raw,
  Right,
  RightMobile,
  SearchBar,
  SearchBarMobile,
  StyledIcon,
  Tel,
  Title,
  Wrapper,
  WrapperMobile,
  Bold,
  IconLink,
} from "./Header.style"
import { useCartWithTotal } from "../../../hooks/useCartWithTotal"

interface Props {
  data: Record<string, any>
}

function HeaderViewCore({ data }: Props) {
  const dispatch = useDispatch()
  const isMobile = useDetectDevice()

  const strapiGmHeader = data.strapiGmHeader
  const searchActive = useSelector(
    state => state.headerStatusReducer.searchActive
  )

  const [isMenuOpen, setOpen] = useState(false)
  const [firstMenuRender, setFirstMenuRender] = useState(true)

  const cartOpened = useSelector(state => state.headerStatusReducer.cartOpened)
  const setCartOpen = value => {
    dispatch(setHeaderStatus({ cartOpened: value }))
  }

  const [firstRender, setFirstRender] = useState(true)
  const { items } = useCartWithTotal()
  const numberOfItems = items?.length || 0

  useEffect(() => {
    if (isMenuOpen) setFirstMenuRender(false)
    if (cartOpened) setFirstRender(false)

    managePageScroll()
  }, [isMenuOpen, cartOpened])

  // At first render I don't want to add any class to popin component
  const getPopinClassName = () => {
    if (firstRender) {
      return ""
    } else {
      if (cartOpened) return "open"
      else return "close"
    }
  }
  // desactive le scroll de la page quand le panier est ouver pour eviter le double scroll panier /page
  const managePageScroll = () => {
    if (cartOpened) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }
  }

  const getMenuPopinClassName = () => {
    if (firstMenuRender) {
      return ""
    } else {
      if (isMenuOpen) return "open"
      else return "close"
    }
  }

  return (
    <CommonWrapperHeader>
      {isMobile && (
        <WrapperMobile>
          <StyledIcon
            data={strapiGmHeader.HamburgerMobile}
            from={enumFrom.STRAPI}
            onClick={() => {
              setOpen(true)
            }}
          />
          <MenuPopin
            className={getMenuPopinClassName()}
            onClickOutside={() => setOpen(false)}
            title="Menu"
            width="80%"
            left="0"
            minWidth="250px"
            height="100%"
          >
            <NavBar setOpen={setOpen} />
          </MenuPopin>
          <SearchBarMobile
            placeholder="Je recherche un produit"
            magnifyingGlass={strapiGmHeader.BandeauHeader.MoteurRecherche}
            open={searchActive}
          />
          {!searchActive && (
            <LogoMobile link="/" data={strapiGmHeader.BandeauHeader.Logo} />
          )}
          <RightMobile className={isMobile && "mobile"}>
            <IconLink
              link="/app/profile"
              data={strapiGmHeader.BandeauHeader.Utilisateur}
            />
            <StyledIcon
              data={strapiGmHeader.BandeauHeader.Panier}
              from={enumFrom.STRAPI}
              className={getPopinClassName()}
              onClick={() => {
                setCartOpen(!cartOpened)
              }}
            />
          </RightMobile>
        </WrapperMobile>
      )}
      {!isMobile && (
        <Wrapper>
          <FlashMessage />
          <Raw>
            <Left>
              <Logo data={strapiGmHeader.BandeauHeader.Logo} link="/"></Logo>
              <SubTitle>Le linge de maison</SubTitle>
            </Left>
            <Middle>
              <SearchBar
                placeholder="Je recherche un produit"
                magnifyingGlass={strapiGmHeader.BandeauHeader.MoteurRecherche}
              />
            </Middle>
            <Right>
              <RightPart strapiGmHeader={strapiGmHeader} />
            </Right>
          </Raw>
          <NavBar setOpen={setOpen} className={getPopinClassName()} />
        </Wrapper>
      )}

      <Popin
        className={getPopinClassName()}
        onClickOutside={e => {
          setCartOpen(false)
        }}
        title="Panier"
        width={isMobile ? "100%" : "30%"}
        minWidth={!isMobile ? "450px" : ""}
        height="100%"
      >
        <Cart
          handleClose={e => {
            setCartOpen(false)
          }}
        />
      </Popin>
    </CommonWrapperHeader>
  )
}

interface CartIconProps {
  urlIcon: string
  from: enumFrom
  setCartOpen: () => void
  numberOfItems: number
}

const RightPart = ({ strapiGmHeader }) => {
  const dispatch = useDispatch()
  const cartOpened = useSelector(state => state.headerStatusReducer.cartOpened)
  const setCartOpen = value => {
    dispatch(setHeaderStatus({ cartOpened: value }))
  }

  return (
    <Icons>
      <QuestionRespons>
        <Title>
          Une question ? <br />
          Besoin d'un conseil ?
        </Title>
        <Flex>
          <Bold>Appelez-nous au </Bold>
          <Tel href="tel:+33582950887">05 82 95 08 87</Tel>
        </Flex>
      </QuestionRespons>

      <CardIconWrapper
        setCartOpen={() => {
          setCartOpen(!cartOpened)
        }}
        urlIcon={strapiGmHeader.BandeauHeader.Panier}
        from={enumFrom.STRAPI}
      ></CardIconWrapper>
      <IconLink
        data={strapiGmHeader.BandeauHeader.Utilisateur.Image}
        link="/app/profile"
      />
    </Icons>
  )
}

const CardIconWrapper = ({ urlIcon, from, setCartOpen }: CartIconProps) => {
  return (
    <>
        <IconLink
          onClick={() => {
            setCartOpen()
          }}
          data={urlIcon}
          from={from}
        />
      <Compteur setCartOpen={setCartOpen} />
    </>
  )
}

const Compteur = ({ setCartOpen }) => {
  const delay = 600
  const { numberOfItems } = useCartWithTotal()
  const [number, setNumber] = useState("")
  const timer = useRef(
    setTimeout(() => {
      setNumber(numberOfItems)
    }, 0)
  )

  useEffect(() => {
    if (numberOfItems) {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        setNumber(numberOfItems)
      }, delay)
    }

    return () => clearTimeout(timer.current)
  }, [numberOfItems])

  return (
    <PastilleCompteur
      onClick={() => {
        setCartOpen()
      }}
    >
      {number}
    </PastilleCompteur>
  )
}

export const HeaderView = React.memo(HeaderViewCore)
